import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Trans } from 'gatsby-plugin-react-i18next'
import { graphql } from 'gatsby'
import QuizOptions from './options'
import Button from '../Buttons/button'
import NavLink from '../Buttons'
import { QuizCorredorContext } from '../../Context/quizCorredor'
import { GlobalContext } from '../../Context/global'

import * as S from './styles'

const QuizTipoCorredor = ({ dadosTipoCorrida }) => {
  const {
    handleSelectOptionCorredor,
    handleLoadNextQuestion,
    handleLoadPrevQuestion,
    perguntaAtual,
    totalPerguntas,
    pontuacao,
    disabled,
    dadosPerguntaSelecionada,
  } = useContext(QuizCorredorContext)

  const { language } = useContext(GlobalContext)
  const data = require(`../../data/quiz_${language}`)

  return (
    <S.QuizWrapper isCorredor>
      <S.QuestionTitle>
        {data.tipoCorredor[perguntaAtual].titulo}
      </S.QuestionTitle>
      <S.QuesionList>
        {data.tipoCorredor[perguntaAtual].alternativas.map(
          (alternativa, index) => (
            <S.QuestionOption key={alternativa.titulo}>
              <QuizOptions
                id={`pergunta${index + 1}`}
                alternativas={alternativa.titulo}
                peso={alternativa.peso}
                score={pontuacao}
                handleSelectOptionCorredor={() =>
                  handleSelectOptionCorredor(
                    alternativa.peso,
                    data.tipoCorredor[perguntaAtual].slug,
                    data.tipoCorredor[perguntaAtual].atributos
                  )
                }
              />
            </S.QuestionOption>
          )
        )}
      </S.QuesionList>
      <S.ButtonsWrapper>
        {perguntaAtual + 1 > 1 && (
          <Button type="button" onClick={handleLoadPrevQuestion}>
            <Trans>Anterior</Trans>
          </Button>
        )}

        {perguntaAtual === totalPerguntas - 1 ? (
          <NavLink
            to={`/quiz/resultado`}
            state={{
              dadosCorrida: dadosTipoCorrida,
              dadosCorredor: dadosPerguntaSelecionada,
            }}
            className={disabled && 'disabled'}
            size="small"
          >
            <Trans>Avançar!</Trans>
          </NavLink>
        ) : (
          <Button
            type="button"
            onClick={handleLoadNextQuestion}
            disabled={disabled}
          >
            <Trans>Próxima</Trans>
          </Button>
        )}
      </S.ButtonsWrapper>

      <S.TotalQuestions>
        {perguntaAtual + 1} / {totalPerguntas}
      </S.TotalQuestions>
    </S.QuizWrapper>
  )
}

QuizTipoCorredor.propTypes = {
  dadosTipoCorrida: PropTypes.array.isRequired,
}

export default QuizTipoCorredor

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
