import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Buttons/button'

import * as S from './styles'

const Error = ({ message = null }) => {
  const handleReloadPage = () => window.location.reload(false)

  return (
    <S.ErrorBox>
      <S.ErrorTextMessage>
        Algo de errado aconteceu.
        <br />
        Clique no botão abaixo para recarregar a página.
        <br />
        {message !== null && <p>Erro: {message}</p>}
      </S.ErrorTextMessage>
      <Button onClick={handleReloadPage}>Recarregar página</Button>
    </S.ErrorBox>
  )
}

Error.propTypes = {
  message: PropTypes.string,
}

export default Error
