import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { navigate, graphql } from 'gatsby'
import { useI18next, Trans, useTranslation } from 'gatsby-plugin-react-i18next'
import Container from '@material-ui/core/Container'
import SEO from '../../../components/SEO'
import Layout from '../../../components/Layout'
import QuizTipoCorredor from '../../../components/Quiz/quizTipoCorredor'
import Loading from '../../../components/Loading'
import ErrorBoundary from '../../../components/ErrorBoundary'

import * as S from '../styles'

const QuizCorredorPage = ({ location }) => {
  const [loading, setLoading] = useState(true)
  const { language } = useI18next()
  const { t } = useTranslation()

  useEffect(() => {
    if (location.state === null || location.state === undefined) {
      navigate(`/${language}/quiz`)
    } else {
      setLoading(false)
    }
  }, [location.state, language])

  if (loading) {
    return <Loading />
  }

  return (
    <Layout>
      <SEO title={t('quiz_title_seo')} description={t('description_seo')} />

      <S.Bg isCorredor>
        <Container>
          <S.PageTitle isCorredor>
            <strong>
              <Trans>Descubra agora que tipo de corredor você é!</Trans>
            </strong>
          </S.PageTitle>
          <S.PageSubTitle isCorredor>
            <Trans>Faça o teste!</Trans>
          </S.PageSubTitle>
          <S.PageAbout>
            <p>
              <Trans>
                Agora que você já entendeu qual é o seu estilo de corrida, de
                acordo com o seu estilo de vida, trazemos uma nova pergunta. Que
                tipo de corredor você é? Faça o teste e descubra!
              </Trans>
              <br />
              <strong>
                <Trans>
                  Qual a sua relação com a corrida? Assinale o quanto você
                  concorda com as opções abaixo e confira o resultado:
                </Trans>
              </strong>
            </p>
          </S.PageAbout>

          <ErrorBoundary>
            <QuizTipoCorredor dadosTipoCorrida={location.state.dadosCorrida} />
          </ErrorBoundary>
        </Container>
      </S.Bg>
    </Layout>
  )
}

QuizCorredorPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default QuizCorredorPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
