import React, { Component, memo } from 'react'
import PropTypes from 'prop-types'
import Error from '../Error'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, errorMessage: '' }
  }

  static getDerivedStateFromError(error) {
    // Atualiza o state para que a próxima renderização mostre a UI alternativa.
    return { hasError: true, errorMessage: error.message }
  }

  /* componentDidCatch(error, errorInfo) {
    // Você também pode registrar o erro em um serviço de relatórios de erro
    console.log('componentDidCatch: ', error)
    console.log('componentDidCatch: ', errorInfo)
  } */

  render() {
    if (this.state.hasError) {
      // Você pode renderizar qualquer UI alternativa
      return <Error />
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object.isRequired,
}

export default memo(ErrorBoundary)
