import React from 'react'
import './style.css'

const Loading = () => (
  <section className="main">
    <div className="loader">Loading...</div>
  </section>
)

export default Loading
