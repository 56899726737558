import React from 'react'
import PropTypes from 'prop-types'

import * as S from './styles'

const Radio = ({ name, id, value, checked, onChange }) => (
  <S.Radio
    type="radio"
    name={name}
    id={id}
    value={value}
    checked={checked}
    onChange={onChange}
  />
)

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Radio
