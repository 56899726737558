import React from 'react'
import PropTypes from 'prop-types'
import Radio from '../Form/radio'


const QuizOptions = ({
  id,
  alternativas,
  peso,
  score,
  handleSelectOption,
  img,
  texto
}) => (
  <>
    <Radio
      name="option"
      id={id}
      value={peso}
      onChange={handleSelectOption}
      checked={score === peso}
    />
    <label htmlFor={id}>
      {img &&
        (<><img htmlFor={id} src={img} alt="" /><br></br></>)
      }
      <div>
        {alternativas}
        {texto &&
          (<p>{texto}</p>)
        } 
      </div>
       
    </label>
  </>
)

QuizOptions.propTypes = {
  id: PropTypes.string.isRequired,
  alternativas: PropTypes.string.isRequired,
  peso: PropTypes.number.isRequired,
  score: PropTypes.number.isRequired,
  handleSelectOption: PropTypes.func,
  texto: PropTypes.string,
}

export default QuizOptions
