import styled from 'styled-components'

export const ErrorBox = styled.section`
  align-items: center;
  background-color: #fff;
  border-radius: 1.125rem;
  box-shadow: 0 0 18px rgb(25 15 85 / 14%);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  margin-top: 30px;
  padding: 1.25rem;
  position: relative;
  width: 100%;
`

export const ErrorTextMessage = styled.h1`
  color: var(--primary-color);
  font-size: 1.5rem;
  font-style: italic;
  letter-spacing: 0.063rem;
  text-align: center;
`
